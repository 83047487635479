import React from 'react';
import { required, TextInput } from 'react-admin';
import { Box } from '@mui/material';

export const ChangePasswordForm: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column" width={'100%'} gap={2}>
      <Box display="flex" flexDirection="row" width={'100%'}>
        <TextInput
          variant="outlined"
          label={'New password'}
          source="newPassword"
          type="password"
          autoComplete="new-password"
          validate={[required()]}
          placeholder="Enter Password"
          defaultValue=""
          fullWidth
          name="newPassword"
        />
      </Box>
    </Box>
  );
};
