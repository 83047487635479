import { createOne, deleteOne, queryList, queryMany, queryManyReference, queryOne, updateOne, updatePassword } from 'query/data';
import { UpdateFunction, UpdateType } from 'types/common';

const getUpdateTypeFunction = (updateType: UpdateType = UpdateType.Normal): UpdateFunction => {
  switch (updateType) {
    case UpdateType.Normal:
      return updateOne;
    case UpdateType.UserPassword:
      return updatePassword;
    default:
      return updateOne;
  }
};

export const dataProvider = {
  getList: (resource: string, params: Record<string, any>) => {
    const { page, perPage } = params.pagination;

    const queryData = {
      filter: params.filter,
      sort: params.sort,
      pagination: params.pagination,
      range: [(page - 1) * perPage, page * perPage - 1],
      search: params.filter?.q ?? '',
    };

    return queryList(resource, queryData);
  },

  getMany: (resource: string, params: Record<string, any>) => {
    const queryData = {
      ids: params.ids,
    };
    return queryMany(resource, queryData);
  },

  getManyReference: (resource: string, params: Record<string, any>) => {
    const filter = {
      [params.target]: params.id,
      ...params.filter,
    };
    const { page, perPage } = params.pagination;
    const queryData = {
      filter: filter,
      sort: params.sort,
      pagination: params.pagination,
      range: [(page - 1) * perPage, page * perPage - 1],
    };
    return queryManyReference(resource, queryData);
  },

  getOne: async (resource: string, params: Record<string, any>) => {
    const data = await queryOne(resource, params.id);
    return Promise.resolve({ data });
  },

  create: async (resource: string, params: Record<string, any>, options?: { onSuccess?: any; onError?: any }) => {
    const queryData = params.data;
    try {
      const data = await createOne(resource, queryData);
      options?.onSuccess?.({ data });
      return Promise.resolve({ data });
    } catch (error) {
      options?.onError?.(error);
      return Promise.reject();
    }
  },

  update: async (resource: string, params: Record<string, any>, options?: { onSuccess?: any; onError?: any }) => {
    const extractResource = resource.split('/');
    const queryData = params.data;
    try {
      const data = await getUpdateTypeFunction(extractResource[1] as UpdateType)(extractResource[0], params.id, queryData);
      options?.onSuccess?.({ data });
      return Promise.resolve({ data });
    } catch (error) {
      options?.onError?.(error);
      return Promise.reject();
    }
  },

  delete: async (resource: string, params: Record<string, any>) => {
    const data = await deleteOne(resource, params.id);
    return Promise.resolve({ data });
  },

  deleteMany: () => Promise.resolve({}),
  updateMany: () => Promise.resolve({}),
};

export default dataProvider;
