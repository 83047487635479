import { COOKIE_KEY } from 'constants/storage-key.constant';
import Cookies from 'js-cookie';
import { configRequestInterceptor } from 'utils/request.util';

export interface AuthInfo {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
}

export class JwtUtil {
  static setAuthInfo(authInfo: AuthInfo): void {
    Cookies.set(COOKIE_KEY.ACCESS_TOKEN, authInfo.accessToken, { expires: authInfo.expiresIn });
    Cookies.set(COOKIE_KEY.REFRESH_TOKEN, authInfo.refreshToken);
    configRequestInterceptor(authInfo.accessToken);
  }

  static clearAuthInfo(): void {
    Cookies.remove(COOKIE_KEY.ACCESS_TOKEN);
    Cookies.remove(COOKIE_KEY.REFRESH_TOKEN);
  }

  static isAuthenticated(): Promise<void> {
    if (Cookies.get(COOKIE_KEY.ACCESS_TOKEN)) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  }

  static getAccessToken(): string {
    return Cookies.get(COOKIE_KEY.ACCESS_TOKEN) || '';
  }
}
