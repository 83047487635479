import * as React from 'react';
import { ElementType, FC, memo } from 'react';
import { DeleteWithConfirmButton, FieldProps, sanitizeFieldRestProps } from 'react-admin';
import Typography, { TypographyProps } from '@mui/material/Typography';
import get from 'lodash/get';
import { useRecordContext } from 'ra-core';
import { fieldPropTypes } from 'types/field';

export const DeleteWithConfirmButtonField: FC<DeleteWithConfirmButtonField> = memo(props => {
  const { className, source, emptyText, name, nameField, ...rest } = props;
  const record = useRecordContext(props);

  return (
    <DeleteWithConfirmButton
      className={className}
      {...sanitizeFieldRestProps(rest)}
      translateOptions={{ name: name || get(record, nameField || 'name') || '' }}
    />
  );
});

// what? TypeScript loses the displayName if we don't set it explicitly
DeleteWithConfirmButtonField.displayName = 'DeleteWithConfirmButtonField';

DeleteWithConfirmButtonField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
};

export interface DeleteWithConfirmButtonField extends FieldProps, Omit<TypographyProps, 'textAlign'> {
  // TypographyProps do not expose the component props, see https://github.com/mui-org/material-ui/issues/19512
  component?: ElementType<any>;
  nameField?: string;
  name?: string;
}
