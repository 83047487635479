import { updateOne, updatePassword } from 'query/data';

let action: <T>(record?: T) => void;

export type ActionOption = {
  availableWithStatus?: unknown;
  label: string;
  type?: string;
  action: typeof action;
};

export enum UpdateType {
  Normal = 'normal',
  UserPassword = 'user_password',
}

export type UpdateFunction = typeof updateOne | typeof updatePassword;
