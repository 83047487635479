export enum UserLevel {
  User = 'User',
  Admin = 'Admin',
  SuperUser = 'SuperUser',
}

export interface UserBasic {
  id: string;
  username: string;
}

export interface User {
  id: string;
  phone: string;
  level: UserLevel;
  tester: boolean;
  updatedAt: string;
  deletedAt: string;
  createdAt: string;
  loggedInAt: string;
}
