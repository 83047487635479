import { axiosInstance } from 'utils/request.util';

export const queryList = (resource: string, queryData: any): Promise<{ data: any[]; total: number }> =>
  axiosInstance.post(`${resource}/list`, queryData);

export const queryMany = (resource: string, queryData: any): Promise<{ data: any[]; total: number }> =>
  axiosInstance.post(`${resource}/ids`, queryData);

export const queryManyReference = (resource: string, queryData: any): Promise<{ data: any[]; total: number }> =>
  axiosInstance.post(`${resource}/list`, queryData);

export const queryOne = (resource: string, id: string): Promise<any> => axiosInstance.get(`${resource}/${id}`);

export const createOne = (resource: string, data: any): Promise<any> => axiosInstance.post(`${resource}`, data);

export const updateOne = (resource: string, id: string, data: any): Promise<any> => axiosInstance.put(`${resource}/${id}`, data);

export const updatePassword = (resource: string, id: string, data: any): Promise<any> =>
  axiosInstance.put(`${resource}/change-password/${id}`, data);

export const deleteOne = (resource: string, id: string): Promise<any> => axiosInstance.delete(`${resource}/${id}`);
