import * as React from 'react';
import { ElementType, FC, memo } from 'react';
import { FieldProps, sanitizeFieldRestProps, useTranslate } from 'react-admin';
import Typography, { TypographyProps } from '@mui/material/Typography';
import get from 'lodash/get';
import { useRecordContext } from 'ra-core';
import { fieldPropTypes } from 'types/field';

export const CustomBooleanField: FC<CustomBooleanField> = memo(props => {
  const translate = useTranslate();

  const { className, source, emptyText, trueLabel, falseLabel, ...rest } = props;
  const record = useRecordContext(props);
  const booleanValue = !!get(record, source || '');
  const value = booleanValue ? trueLabel || 'ra.boolean.true' : falseLabel || 'ra.boolean.false';

  return (
    <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
      {translate(value)}
    </Typography>
  );
});

// what? TypeScript loses the displayName if we don't set it explicitly
CustomBooleanField.displayName = 'TextField';

CustomBooleanField.propTypes = {
  ...fieldPropTypes,
};

export interface CustomBooleanField extends FieldProps, Omit<TypographyProps, 'textAlign'> {
  // TypographyProps do not expose the component props, see https://github.com/mui-org/material-ui/issues/19512
  component?: ElementType<any>;
  trueLabel?: string;
  falseLabel?: string;
}
