import englishMessages from 'ra-language-english';

const customEnglishMessages = {
  ...englishMessages,
  pos: {
    search: 'Search',
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      monthly_revenue: 'Monthly Revenue',
      month_history: '30 Day Revenue History',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      all_reviews: 'See all reviews',
      new_customers: 'New Customers',
      all_customers: 'See all customers',
      pending_orders: 'Pending Orders',
      order: {
        items: 'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to the react-admin e-commerce demo',
        subtitle:
          "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
        ra_button: 'react-admin site',
        demo_button: 'Source for this demo',
      },
    },
    menu: {
      sales: 'Sales',
      catalog: 'Catalog',
      customers: 'Customers',
    },
  },
  resources: {
    users: {
      name: 'Users',
      fields: {
        username: 'Username',
        level: 'Level',
        tester: 'Tester',
        signature: 'Signature',
        created_at: 'Created At',
        updated_at: 'Updated At',
        logged_in_at: 'Logged In At',
      },
      level: {
        superuser: 'Super User',
        admin: 'Admin',
        view_only: 'View Only',
      },
      notify: {
        create_success: 'Created user successfully',
        create_failed: 'Create user failed',
        update_password_success: 'Updated user password successfully',
        update_password_failed: 'Update user password failed',
      },
      confirmation: {
        delete_title: 'Delete #%{username} #%{id}',
      },
      tabs: {
        basic_info: 'Basic Info',
        password: 'Password',
      },
    },
    notifications: {
      name: 'Notifications',
      fields: {
        username: 'Username',
        environment: 'Environment',
        platform: 'Platform',
        device_id: 'Device ID',
        fail_count: 'Fail Count',
        token: 'Token',
        created_at: 'Created At',
        updated_at: 'Updated At',
      },
      environment: {
        dev: 'Dev',
        prod: 'Production',
      },
      platform: {
        ios: 'IOS',
        android: 'Android',
      },
    },
  },
};

export default customEnglishMessages;
