import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import { lightTheme } from 'layout/themes';
import dataProvider from 'providers/dataProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import englishMessages from './i18n/en';
import users from './modules/users';
import authProvider from './providers/authProvider';
import { Layout, Login } from './layout';

const i18nProvider = polyglotI18nProvider(() => {
  // Always fallback on english
  return englishMessages;
}, 'en');

const App = () => {
  return (
    <Admin
      title=""
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={Login}
      i18nProvider={i18nProvider}
      layout={Layout}
      disableTelemetry
      theme={lightTheme}
    >
      <Resource name="users" {...users} />
    </Admin>
  );
};

export default App;
