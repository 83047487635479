import axios, { AxiosResponse, CreateAxiosDefaults } from 'axios';
import { API_URL } from 'constants/app.constant';
import humps from 'humps';

import { JwtUtil } from './jwt.utils';

const axiosConfig: CreateAxiosDefaults = {
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
};

export const axiosInstance = axios.create(axiosConfig);

let requestInterceptor = axiosInstance?.interceptors.request.use(
  function (config) {
    config.params = humps.decamelizeKeys(config.params);
    config.data = humps.decamelizeKeys(config.data);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance?.interceptors.response.use(
  function (response) {
    return humps.camelizeKeys(response.data) as AxiosResponse;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const configRequestInterceptor = (accessToken?: string): void => {
  requestInterceptor && axiosInstance.interceptors.request.eject(requestInterceptor);

  requestInterceptor = axiosInstance.interceptors.request.use(
    function (config) {
      config.params = humps.decamelizeKeys(config.params);
      config.data = humps.decamelizeKeys(config.data);

      if (accessToken || JwtUtil.getAccessToken()) {
        config.headers.Authorization = `Bearer ${accessToken || JwtUtil.getAccessToken()}`;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};

configRequestInterceptor();
